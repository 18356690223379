#BiginWebToRecordForm691033000000504002 {
  padding: 20px !important;
  max-width: 500px;
  margin: 0 auto;

  @media (max-width: 599px) {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.wf-parent {
  background-color: #011553 !important;
}
.wf-wrapper {
  @media (min-width: 600px) {
    padding: 30px;
  }
}

.wf-header {
  color: white !important;
}

.wf-field-checkbox {
  width: 25px;
  height: 25px;
  margin: 0 !important;
  border-radius: 50px !important;
}

#formsubmit {
  width: 100% !important;
  background-color: #09b192 !important;
  border: none !important;
}
.wf-field-inner {
  display: flex !important;
  align-items: flex-start !important;
  color: white !important;
  gap: 10px !important;
  font-size: 16px !important;
}
.wf-field-wr {
  display: flex !important;
  flex-direction: column !important;
}
.wf-cp {
  color: #f54b53;
  font-size: 0.8rem;
  font-style: italic;
}

.wf-field-error {
  color: #f54b53;
  font-size: 0.8rem;
  font-style: italic;
}
.wf-wrapper-response {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  > h2 {
    color: white !important;
    font-size: 1.5rem;
  }
}
